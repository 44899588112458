import {
  Column,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IFilterArray, IFilterTable, IRowItem } from "./table.interface";
import React, { CSSProperties, useState } from "react";
import "./table.component.css";
import Popover from "components/popover/popover";
import { StatusFilter, toggleEnum, Top3Filter } from "enums/glimps.enums";

const GlimpseTable = ({
  columns,
  data,
  totalData,
  currentPage,
  totalPage,
  rowSelected,
  onSearch,
  previousPage,
  nextPage,
  exportData,
  enableFilter,
  enableSearch,
  enableExport,
  enablePagination,
  preventClickOn,
  loadingData,
  filterItems,
  filterChanged,
  sorting,
  setSorting,
  isPartner,
}: IFilterTable) => {
  // const [sorting, setSorting] = React.useState<SortingState>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  // const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
  });

  const getCommonPinningStyles = (
    column: Column<IRowItem>,
    index: number,
    type?: string
  ): CSSProperties => {
    if (column.columnDef.header === "") {
      return {
        right: `${column.getStart("left")}px`,
        position: "sticky",
        width: 10,
        zIndex: 10,
        padding: 0,
      };
    } else {
      return {
        position: "relative",
      };
    }
  };





  return (
    <>
      {(enableExport || enableFilter || enablePagination || enableSearch) && (
        <div className="flex flex-col justify-between gap-2 p-1 pt-4 md:flex-row">
          <div className="grid items-center w-full grid-cols-12 gap-2">
            <div className="order-2 col-span-10 mr-2 md:mr-0 md:order-1 md:col-span-6">
              <div className="relative w-full lg:w-[300px] search">
                {enableSearch && (
                  <>
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={15}
                        height={16}
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2553_24724)">
                          <path
                            d="M10.9035 10.0462C12.6045 7.69325 12.3964 4.37879 10.279 2.26109C7.93059 -0.0873571 4.10955 -0.0873571 1.76109 2.26109C-0.587357 4.60955 -0.587357 8.43059 1.76109 10.779C3.87849 12.8964 7.19295 13.1045 9.54618 11.4035L13.3616 15.2189C13.7365 15.5939 14.3443 15.5939 14.7189 15.2189C15.0939 14.844 15.0939 14.2362 14.7189 13.8616L10.9035 10.0462ZM2.65098 9.88916C0.793295 8.03147 0.793295 5.00867 2.65098 3.15098C4.50867 1.29329 7.53147 1.29329 9.38916 3.15098C11.2468 5.00867 11.2468 8.03147 9.38916 9.88916C7.53147 11.7468 4.50867 11.7468 2.65098 9.88916Z"
                            fill="#707071"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2553_24724">
                            <rect
                              width={15}
                              height={15}
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="input-group-1"
                      className="block w-full text-xs text-black border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      placeholder="Search by name..."
                      onChange={onSearch}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="order-1 md:order-2 col-span-full md:col-span-5">
              <div className="flex justify-between order-1 w-full gap-8 lg:justify-end">
                {enableExport && (
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={exportData}
                  >
                    <span className="text-headerBlue ">Export</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={14}
                      viewBox="0 0 16 14"
                      fill="none"
                    >
                      <path
                        d="M14.3998 9.30793V11.07C14.3998 11.5163 14.0416 11.8779 13.5998 11.8779H2.39996C1.9581 11.8779 1.59997 11.5163 1.59997 11.07V9.30793C1.59997 8.86168 1.24185 8.5 0.799987 8.5C0.358127 8.5 0 8.86168 0 9.30793V11.3431C0 12.5343 0.956251 13.5 2.1357 13.5H13.8643C15.0437 13.5 16 12.5343 16 11.3431V9.30793C16 8.86168 15.6419 8.5 15.2 8.5C14.7582 8.5 14.4 8.86168 14.4 9.30793H14.3998Z"
                        fill="#60849F"
                      />
                      <path
                        d="M8.91783 8.06588H6.71777V1.60016C6.71777 0.992667 7.21017 0.5 7.81794 0.5C8.42543 0.5 8.9181 0.992398 8.9181 1.60016V8.06614L8.91783 8.06588Z"
                        fill="#60849F"
                      />
                      <path
                        d="M12.8738 4.47465C12.4441 4.04491 11.7476 4.04491 11.3181 4.47465L7.81756 7.97522L4.31699 4.47465C3.88725 4.04491 3.19074 4.04491 2.76127 4.47465C2.33153 4.90439 2.33153 5.6009 2.76127 6.03037L7.16192 10.431C7.52416 10.7933 8.11122 10.7933 8.47346 10.431L12.8741 6.03037C13.3039 5.60063 13.3039 4.90412 12.8741 4.47465H12.8738Z"
                        fill="#60849F"
                      />
                    </svg>
                  </div>
                )}
                {enablePagination && (
                  <div className="flex items-center gap-5 cursor-pointer">
                    <span className="gap-4 font-semibold whitespace-nowrap">
                      {currentPage}-{totalPage} of {totalData}
                    </span>
                    <div className="flex items-center gap-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width={15}
                        height={15}
                        viewBox="0 0 256 256"
                        xmlSpace="preserve"
                        onClick={previousPage}
                      >
                        <g
                          className="opacity-100 stroke-current stroke-dash-0 stroke-linecap-butt stroke-linejoin-miter stroke-miterlimit-10 fill-none fill-rule-nonzero"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                        >
                          <path
                            d="M 65.75 90 c 0.896 0 1.792 -0.342 2.475 -1.025 c 1.367 -1.366 1.367 -3.583 0 -4.949 L 29.2 45 L 68.225 5.975 c 1.367 -1.367 1.367 -3.583 0 -4.95 c -1.367 -1.366 -3.583 -1.366 -4.95 0 l -41.5 41.5 c -1.367 1.366 -1.367 3.583 0 4.949 l 41.5 41.5 C 63.958 89.658 64.854 90 65.75 90 z"
                            transform=" matrix(1 0 0 1 0 0) "
                            className="opacity-100 stroke-none stroke-dash-0 stroke-linecap-butt stroke-linejoin-miter stroke-miterlimit-10 fill-black fill-rule-nonzero"
                            strokeLinecap="round"
                          />
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width={15}
                        height={15}
                        viewBox="0 0 256 256"
                        xmlSpace="preserve"
                        onClick={nextPage}
                      >
                        <g
                          className="opacity-100 stroke-current stroke-dash-0 stroke-linecap-butt stroke-linejoin-miter stroke-miterlimit-10 fill-none fill-rule-nonzero"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                        >
                          <path
                            d="M 24.25 90 c -0.896 0 -1.792 -0.342 -2.475 -1.025 c -1.367 -1.366 -1.367 -3.583 0 -4.949 L 60.8 45 L 21.775 5.975 c -1.367 -1.367 -1.367 -3.583 0 -4.95 c 1.367 -1.366 3.583 -1.366 4.95 0 l 41.5 41.5 c 1.367 1.366 1.367 3.583 0 4.949 l -41.5 41.5 C 26.042 89.658 25.146 90 24.25 90 z"
                            className="opacity-100 stroke-none stroke-dash-0 stroke-linecap-butt stroke-linejoin-miter stroke-miterlimit-10 fill-black fill-rule-nonzero"
                            transform=" matrix(1 0 0 1 0 0) "
                            strokeLinecap="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end order-3 col-span-2 md:col-span-1">
              {enableFilter && (
                <Popover
                  button={
                    <div className="">
                      <button
                        type="button"
                        className="p-2 text-black rounded-full bg-headerBlue"
                        title="table_filter"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height={17}
                          viewBox="0 0 19 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2553_24735)">
                            <path
                              d="M17.8728 7.05115H6.00146C6.03419 7.19581 6.05192 7.34588 6.05192 7.49992C6.05192 7.65396 6.03419 7.80408 6.00146 7.94874H17.8729C18.1272 7.94874 18.3334 7.74782 18.3334 7.49992C18.3334 7.25201 18.1272 7.05115 17.8729 7.05115H17.8728Z"
                              fill="white"
                            />
                            <path
                              d="M1.82299 7.05115H0.460501C0.206171 7.05115 0 7.25207 0 7.49992C0 7.74777 0.206227 7.94874 0.460501 7.94874H1.82293C1.79021 7.80408 1.77248 7.65402 1.77248 7.49992C1.77248 7.34582 1.79021 7.19576 1.82293 7.05115H1.82299Z"
                              fill="white"
                            />
                            <path
                              d="M17.8729 1.63635H16.7234C16.7561 1.78102 16.7738 1.93103 16.7738 2.08512C16.7738 2.23922 16.7561 2.38928 16.7234 2.53389H17.8729C18.1273 2.53389 18.3334 2.33297 18.3334 2.08512C18.3334 1.83727 18.1273 1.63635 17.8729 1.63635Z"
                              fill="white"
                            />
                            <path
                              d="M12.5449 1.63635H0.460501C0.206171 1.63635 0 1.83727 0 2.08512C0 2.33297 0.206227 2.53389 0.460501 2.53389H12.5448C12.5121 2.38923 12.4944 2.23922 12.4944 2.08512C12.4944 1.93103 12.5121 1.78096 12.5448 1.63635H12.5449Z"
                              fill="white"
                            />
                            <path
                              d="M17.8729 12.4659H12.8562C12.8889 12.6106 12.9067 12.7606 12.9067 12.9147C12.9067 13.0688 12.8889 13.2189 12.8562 13.3635H17.8729C18.1273 13.3635 18.3334 13.1626 18.3334 12.9147C18.3334 12.6668 18.1273 12.4659 17.8729 12.4659Z"
                              fill="white"
                            />
                            <path
                              d="M8.6777 12.4659H0.460501C0.206171 12.4659 0 12.6669 0 12.9147C0 13.1626 0.206227 13.3635 0.460501 13.3635H8.67764C8.64492 13.2189 8.62719 13.0689 8.62719 12.9147C8.62719 12.7606 8.64492 12.6106 8.67764 12.4659H8.6777Z"
                              fill="white"
                            />
                            <path
                              d="M16.7233 1.63641C16.512 0.701692 15.6558 0 14.6341 0C13.6123 0 12.7562 0.701692 12.5448 1.63641C12.5121 1.78107 12.4944 1.93108 12.4944 2.08518C12.4944 2.23928 12.5121 2.38934 12.5448 2.53395C12.7561 3.46867 13.6123 4.17036 14.6341 4.17036C15.6558 4.17036 16.512 3.46867 16.7233 2.53395C16.756 2.38928 16.7737 2.23928 16.7737 2.08518C16.7737 1.93108 16.756 1.78102 16.7233 1.63641ZM14.6341 3.27276C13.9621 3.27276 13.4154 2.73999 13.4154 2.08518C13.4154 1.43037 13.9621 0.897596 14.6341 0.897596C15.306 0.897596 15.8527 1.43037 15.8527 2.08518C15.8527 2.73999 15.3061 3.27276 14.6341 3.27276Z"
                              fill="white"
                            />
                            <path
                              d="M6.00137 7.05115C5.79011 6.11643 4.93388 5.41479 3.91214 5.41479C2.8904 5.41479 2.03423 6.11643 1.82291 7.05115C1.79019 7.19581 1.77246 7.34588 1.77246 7.49992C1.77246 7.65396 1.79019 7.80408 1.82291 7.94875C2.03417 8.88346 2.8904 9.5851 3.91214 9.5851C4.93388 9.5851 5.79005 8.88341 6.00137 7.94875C6.03409 7.80408 6.05182 7.65402 6.05182 7.49992C6.05182 7.34582 6.03409 7.19576 6.00137 7.05115ZM3.91214 8.6875C3.24021 8.6875 2.69352 8.15473 2.69352 7.49992C2.69352 6.84511 3.24021 6.31234 3.91214 6.31234C4.58407 6.31234 5.13077 6.84511 5.13077 7.49992C5.13077 8.15473 4.58407 8.6875 3.91214 8.6875Z"
                              fill="white"
                            />
                            <path
                              d="M12.8561 12.4659C12.6448 11.5312 11.7886 10.8296 10.7669 10.8296C9.74514 10.8296 8.88897 11.5313 8.67765 12.4659C8.64493 12.6106 8.6272 12.7606 8.6272 12.9147C8.6272 13.0688 8.64493 13.2189 8.67765 13.3635C8.88891 14.2983 9.74514 14.9999 10.7669 14.9999C11.7886 14.9999 12.6448 14.2982 12.8561 13.3635C12.8888 13.2189 12.9066 13.0689 12.9066 12.9147C12.9066 12.7606 12.8888 12.6106 12.8561 12.4659ZM10.7669 14.1024C10.0949 14.1024 9.54825 13.5696 9.54825 12.9148C9.54825 12.26 10.0949 11.7272 10.7669 11.7272C11.4388 11.7272 11.9856 12.26 11.9856 12.9148C11.9856 13.5696 11.4389 14.1024 10.7669 14.1024Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2553_24735">
                              <rect width="18.3333" height={15} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  }
                  isOpen={showFilters}
                  setIsOpen={setShowFilters}
                >
                  <>



                    <label className="block text-[16px] leading-6 pb-4 border-b border-horizontalRow ">
                      Filters
                    </label>
                    {filterItems?.map((item, index) => {
                      return (
                        <div key={item.label}>
                          <div className="mt-3 text-sm font-bold">
                            {item.label}
                          </div>
                          {item.filters.map((childItem, childIndex) => {
                            return (
                              <>
                                <label
                                  key={childItem.name}
                                  className="flex items-center"
                                >
                                  <input
                                    className="w-[13px] h-[13px] rounded-sm"
                                    type={"checkbox"}
                                    id={item.label}
                                    name={item.label}
                                    checked={childItem.is_checked}
                                    onChange={(ev) => {
                                      let filterItemsCopied = [...filterItems];
                                      filterItems[index].filters.forEach(
                                        (item) => {
                                          item.is_checked = false;
                                        }
                                      );
                                      filterItems[index].filters[
                                        childIndex
                                      ].is_checked = ev.target.checked;
                                      filterChanged(
                                        filterItemsCopied,
                                        ev.target.checked? filterItems[index].filters[
                                            childIndex
                                          ].name:'' as any
                                          
                                      );
                                    }}
                                  />

                                  <span
                                    className={`h-[32px] w-full pl-1 text-xs flex items-center font-semibold cursor-pointer capitalize`}
                                  >
                                    {childItem.label}
                                  </span>
                                </label>
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                </Popover>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="relative w-full gap-1 overflow-x-auto overflow-y-hidden p-1 pb-[200px] shadow-none ">
        <table className="w-full text-left rounded-xl rtl:text-right text-textColor shadow-card">
          <thead className=" text-[14px] capitalize text-headerBlack bg-tableHeader">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="h-[40px]">
                {headerGroup.headers.map((header, index, data) => {
                  const { column } = header;
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStyles(column, (index = 1)) }}
                      className="font-semibold"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? "cursor-pointer select-none ml-5 flex items-center gap-2"
                              : "ml-5"
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <span className="text-2xl text-lightBrown">
                                ▴
                              </span>
                            ),
                            desc: (
                              <span className="text-2xl text-lightBrown">
                                ▾
                              </span>
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="group">
            {loadingData ? (
              <tr className="w-full text-center">
                <td colSpan={columns.length} className="text-md">
                  Loading...
                </td>
              </tr>
            ) : table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={`h-10 cursor-pointer even:bg-tableHeader even:bg-gray-50 hover:bg-background`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      // className={`relative md:sticky hover:bg-background font-normal text-[14px] right-[${cell.column.getStart("left")}px]`}
                      className={`px-6 font-normal text-[14px] ${cell.column.columnDef.header === "" ? index % 2 === 0 ? "bg-white" : "bg-tableHeader" : null} group-hover:bg-transparent`}
                      style={{
                        ...getCommonPinningStyles(
                          cell.column,
                          index,
                          isPartner ? row.getValue("type") : undefined
                        ),
                      }}
                      onClick={() => {
                        if (!preventClickOn?.includes(cell.column.id)) {
                          rowSelected(row.original);
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="w-full text-center">
                <td colSpan={columns.length} className="text-md">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GlimpseTable;
