import GlimpseSmallCard from "components/card/smallCard.component";
import DughnutComponent from "components/charts/doughnut.component";
import GlimpsePopup from "components/popup/popup.component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "components/tabs/tabs.component";
import { ITabItem } from "components/tabs/tabs.interface";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addNewPartner,
  deletePartner,
  getPartnerById,
  getPartnermembers,
  updatePartnerInfo,
} from "services/partners.service";
import AddPartner from "./addPartner";
import {
  IPartnerResult,
  IReinviteMember,
} from "interfaces/AddPartnersInterface";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import Loading from "components/loading/loading.component";
import NotFound from "screens/NotFound";
import GlimpseTable from "components/table/table.component";
import { createColumnHelper } from "@tanstack/react-table";
import {
  IFilterArray,
  IRowItem,
  ITopThreeCareer,
} from "components/table/table.interface";
import { IDownloadReport, IPagination } from "interfaces/BaseInterface";
import {
  deleteInviteMember,
  deleteMember,
  downloadReportFile,
  reInviteMember,
} from "services/members.service";
import ResetPasswordPartner from "components/reset-password/resetPasswordPartner";
import ResetPasswordMember from "components/reset-password/resetPasswordMember";
import Dropdown from "components/dropdown/dropdown.component";
import TimeAgo from "utills/time-ago";
import { PaginationFilter, StatusFilter, Top3Filter } from "enums/glimps.enums";
import { filterItemsDatas } from "utills/Filter-Options";

const tabsList: ITabItem[] = [
  { name: "Overview" },
  { name: "Licenses" },
  { name: "Account Info" },
];

const PartnerInsightScreen: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [filterItems, setFilterItems] =
    useState<IFilterArray[]>(filterItemsDatas);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletePartnerAlert, setDeletePartnerAlert] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [displayPopupPassword, setDisplayPopupPassword] = useState(false);
  const [displayPopupPasswordMember, setDisplayPopupPasswordMember] =
    useState(false);
  const [popupMemberData, setPopupMemberData] = useState<IRowItem | null>(null);

  let { param } = useParams();
  const columnHelper = createColumnHelper<IRowItem>();
  const [membersPagination, setmembersPagination] = useState<IPagination>({
    page: 1,
    page_size: 25,
    top_ten: true,
    search: "",
    status: "",
    top_three: "",
    type: "",
  });

  // reinvitation
  const reSendInvitationForMember = useMutation({
    mutationFn: (data: IReinviteMember) => reInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member reinvitation has been sent successfully");
    },
    onError: () => {
      displayError("Unable to reinvite member.");
    },
  });

  const handleReinvitationSubmit = (email: string) => {
    const payload = { email: email };
    reSendInvitationForMember.mutateAsync(payload);
  };

  // handlePartner Delete
  const handleDeletePartner = useMutation({
    mutationFn: (data: string) => deletePartner(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Partner has been deleted successfully.");
      refetch();
    },
    onError: () => {
      displayError("Unable to delete partner.");
    },
  });

  const handleDeletePartnerSubmit = () => {
    if (param) {
      handleDeletePartner.mutateAsync(param as string);
    }
  };

  // handlemember delete
  const handleDeleteMember = useMutation({
    mutationFn: (data: string) => deleteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member has been deleted successfully.");
    },
    onError: () => {
      displayError("Unable to delete member.");
    },
  });

  const handleDeleteInvitedMember = useMutation({
    mutationFn: (data: string) => deleteInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member has been deleted successfully.");
    },
    onError: () => {
      displayError("Unable to delete member.");
    },
  });

  const handleDeleteMemberSubmit = (values: IRowItem) => {
    if (values.status === "active") {
      handleDeleteMember.mutateAsync(values.id);
    } else {
      handleDeleteInvitedMember.mutateAsync(values.id);
    }
  };

  const individualsMembersColumns = [
    columnHelper.accessor("member", {
      cell: (info) => (
        <div className="flex items-center gap-1">
          {info.getValue().avatar && (
            <img
              className="w-[24px] h-[24px]"
              src={info.getValue().avatar}
              alt="career_one_icon"
            />
          )}
          <span>{info.getValue().name}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.top_three, {
      id: "top_three",
      cell: (info) => (
        <>
          <div className="flex">
            {info.getValue()
              ? info.getValue().map((item: ITopThreeCareer, index: number) => {
                  return (
                    <img
                      key={index}
                      className="min-w-[24px] h-[24px]"
                      src={item.icon_url || ""}
                      alt={`Career Icon ${index + 1}`}
                    />
                  );
                })
              : ""}
          </div>
        </>
      ),
      header: () => <span>Top Three</span>,
    }),
    columnHelper.accessor("videos_watched", {
      header: () => "Video Watched",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("modules_completed", {
      header: () => "Module Completed",
    }),
    columnHelper.accessor("career_ig_level", {
      header: "Career Awareness Level",
      cell: (info) =>
        info.getValue() && (
          <img
            className="w-[24px] h-[24px]"
            src={`/assets/icons/${info
              .getValue()
              .split(" ")[0]
              .toLowerCase()}_badge.png`}
            alt={info.getValue() || "career_awareness_icon"}
          />
        ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
 
    columnHelper.accessor("last_active", {
      header: "Last Active",
      cell: (info) =>
        info.getValue() ? (
          <span className="capitalize text-nowrap">
            <TimeAgo date={new Date(info.getValue() as string)} />
          </span>
        ) : (
          "--"
        ),
      // <span>
      //   {info.getValue() ? new Date(info.getValue()).toDateString() : "--"}
      // </span>
    }),
    columnHelper.accessor("dob", {
      header: "Birth Year",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
    columnHelper.accessor("id", {
      header: "",
      maxSize: 12,
      cell: (info) => {
        const dropdownActivetableItems = [
          {
            label: "Send Email",
            onClick: () =>
              (window.location.href = `mailto:${info.row.original.email}`),
          },
          {
            label: "Reset Password",
            onClick: () => {
              setDisplayPopupPasswordMember(true);
              setPopupMemberData(info.row.original);
            },
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];

        const dropdownInvitedtableItems = [
          {
            label: "Resend Invite",
            onClick: () => handleReinvitationSubmit(info.row.original.email),
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];

        return (
          <>
            <Dropdown
              buttonText=""
              onOpened={(data) => {}}
              items={
                info.row.original.status === "active"
                  ? dropdownActivetableItems
                  : dropdownInvitedtableItems
              }
              tableComponent={true}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#414346"
                  />
                </svg>
              }
            />
          </>
        );
      },
    }),
  ];

  const {
    data: partnerInfo,
    // isLoading: isPartnerInfoLoading,
    isFetching: isPartnerInfoLoading,
    refetch,
  } = useQuery({
    queryKey: ["GetPartnerById", param],
    queryFn: () => getPartnerById(param as string),
    enabled: !!param,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const downloadReport = useMutation({
    mutationFn: (reportParams: IDownloadReport) =>
      downloadReportFile(reportParams),
    mutationKey: ["DownloadReport"],
    retry: 0,
    onSuccess: (data: Blob) => {
      const link: HTMLElement = document.createElement("a");
      const blob: Blob = new Blob([data]);
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute(
        "download",
        `${
          partnerInfo?.name
        }'s Individual members ${new Date().toDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  const registerPartner = useMutation({
    mutationFn: (partners: IPartnerResult) => {
      if (partners.id) {
        return updatePartnerInfo(partners);
      } else {
        return addNewPartner(partners);
      }
    },
    retry: 0,
    onSuccess: (partners: IPartnerResult) => {
      displaySuccess(
        `${
          !partners.id
            ? `${partners.name} Information has been updated successfully.`
            : "New partner has been added successfully."
        }`
      );

      refetch();
      setDisplayPopup(false);
    },
    onError: (partners: IPartnerResult) => {
      displayError(`Unable to ${!partners.id ? "Update " : "Add"} Partner`);
    },
  });

  const isPartnerDialogLoading = registerPartner.isPending;

  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
    if (index === 1) {
      refetchMembersList();
    }
  };

  const {
    data: partnerMemberList,
    isFetching: isPartnerMemberListLoading,
    refetch: refetchMembersList,
  } = useQuery({
    queryKey: ["GetAllPartners", membersPagination],
    queryFn: () => {
      return getPartnermembers(
        { ...membersPagination },
        partnerInfo?.id as string
      );
    },
    enabled: !!membersPagination,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // calculate center data
  const earnedBronzePercentage =
    partnerInfo &&
    partnerInfo.bronze_badge_count !== 0 &&
    partnerInfo?.assigned_licence_count &&
    partnerInfo?.assigned_licence_count !== 0
      ? (partnerInfo.bronze_badge_count / partnerInfo.assigned_licence_count) *
        100
      : 0;

  const topThreePercentage =
    partnerInfo &&
    partnerInfo.top_three_assigned_users !== 0 &&
    partnerInfo?.assigned_licence_count &&
    partnerInfo?.assigned_licence_count !== 0
      ? (partnerInfo.top_three_assigned_users /
          partnerInfo.assigned_licence_count) *
        100
      : 0;

  //   OVERVIEW CHART PERCENTAGE
  const chartDataBronzeEarned = {
    datasets: [
      {
        data: [earnedBronzePercentage, 100 - earnedBronzePercentage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };
  const chartDataTopthree = {
    datasets: [
      {
        data: [topThreePercentage, 100 - topThreePercentage],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  const dropdownItems = [
    {
      label: "Send Email",
      onClick: () =>
        (window.location.href = `mailto:${partnerInfo?.contact_email}`),
    },
    { label: "Edit Account", onClick: () => setDisplayPopup(true) },
    { label: "Reset Password", onClick: () => setDisplayPopupPassword(true) },
    { label: "Delete", onClick: () => setDeletePartnerAlert(true) },
  ];

  return (
    <>
      {isPartnerInfoLoading ? (
        <Loading />
      ) : partnerInfo ? (
        <>
          <div className="p-1">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
            >
              <div className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="12"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                    fill="black"
                  />
                </svg>
                <span className="pl-2 font-semibold">Back</span>
              </div>
            </button>
            <div className="flex flex-col gap-3 p-3 overflow-auto info w-100 ">
              <div className="text-[16px] leading-6 ">
                <div className="text-[20px]">{partnerInfo?.name}</div>
                <div>{partnerInfo?.contact_person}</div>
                <div>{partnerInfo?.contact_email}</div>
                <div>
                  Partner Since{" "}
                  {partnerInfo
                    ? new Date(partnerInfo?.user.date_joined).getFullYear()
                    : null}
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-3">
                <a href={`mailto: ${partnerInfo?.contact_email}`}>
                  <button
                    type="button"
                    onClick={() => {}}
                    className="text-white text-center bg-green gap-3  w-[121px] h-[32px] rounded-full text-sm font-normal leading-5"
                  >
                    Send Email
                  </button>
                </a>
                <Dropdown
                  tableComponent={false}
                  buttonText=""
                  onOpened={(data) => {}}
                  items={dropdownItems}
                  svg={
                    <svg
                      width="44"
                      height="33"
                      viewBox="0 0 44 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.510776 16.045C0.510776 7.48517 7.44992 0.546023 16.0098 0.546023H28.0098C36.5697 0.546023 43.5088 7.48517 43.5088 16.045C43.5088 24.6049 36.5697 31.5441 28.0098 31.5441H16.0098C7.44992 31.5441 0.510776 24.6049 0.510776 16.045Z"
                        stroke="#60849F"
                        stroke-width="1.00196"
                      />
                      <path
                        d="M14.0098 16.045C14.0098 15.495 14.2055 15.0244 14.5968 14.633C14.9888 14.241 15.4598 14.045 16.0098 14.045C16.5598 14.045 17.0308 14.241 17.4228 14.633C17.8141 15.0244 18.0098 15.495 18.0098 16.045C18.0098 16.595 17.8141 17.066 17.4228 17.458C17.0308 17.8494 16.5598 18.045 16.0098 18.045C15.4598 18.045 14.9888 17.8494 14.5968 17.458C14.2055 17.066 14.0098 16.595 14.0098 16.045ZM20.0098 16.045C20.0098 15.495 20.2058 15.0244 20.5978 14.633C20.9891 14.241 21.4598 14.045 22.0098 14.045C22.5598 14.045 23.0308 14.241 23.4228 14.633C23.8141 15.0244 24.0098 15.495 24.0098 16.045C24.0098 16.595 23.8141 17.066 23.4228 17.458C23.0308 17.8494 22.5598 18.045 22.0098 18.045C21.4598 18.045 20.9891 17.8494 20.5978 17.458C20.2058 17.066 20.0098 16.595 20.0098 16.045ZM26.0098 16.045C26.0098 15.495 26.2058 15.0244 26.5978 14.633C26.9891 14.241 27.4598 14.045 28.0098 14.045C28.5598 14.045 29.0305 14.241 29.4218 14.633C29.8138 15.0244 30.0098 15.495 30.0098 16.045C30.0098 16.595 29.8138 17.066 29.4218 17.458C29.0305 17.8494 28.5598 18.045 28.0098 18.045C27.4598 18.045 26.9891 17.8494 26.5978 17.458C26.2058 17.066 26.0098 16.595 26.0098 16.045Z"
                        fill="#60849F"
                      />
                    </svg>
                  }
                />
                <GlimpsePopup
                  title=""
                  onClose={() => {
                    setDeletePartnerAlert(false);
                  }}
                  showCross={false}
                  popup={deletePartnerAlert}
                  customClass="max-w-[347px] z-10"
                >
                  <div className="text-headerBlue text-[20px] flex items-start">
                    Delete Partner Account
                  </div>
                  <div>
                    If you delete
                    <span className="px-1 font-bold">
                      {partnerInfo?.name}'s
                    </span>
                    membership, their account data will be permanently deleted.
                    This action cannot be undone.
                  </div>
                  <div className="flex justify-between mt-5">
                    <button
                      type="button"
                      onClick={() => setDeletePartnerAlert(false)}
                      className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
                    >
                      Close
                    </button>

                    <button
                      className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                      onClick={() => {
                        handleDeletePartnerSubmit();
                        setDeletePartnerAlert(false);
                        navigate("/partners");
                      }}
                    >
                      Sure Delete
                    </button>
                  </div>
                </GlimpsePopup>
                <GlimpsePopup
                  title=""
                  onClose={() => {
                    setDisplayPopup(false);
                  }}
                  showCross={true}
                  customClass="max-w-[347px]"
                  popup={displayPopup}
                >
                  <AddPartner
                    partnerInfo={partnerInfo as IPartnerResult}
                    isLoading={isPartnerDialogLoading}
                    onSubmit={(data) => {
                      registerPartner.mutateAsync(data);
                    }}
                    onClose={() => {
                      setDisplayPopup(false);
                    }}
                  />
                </GlimpsePopup>
                <GlimpsePopup
                  title=""
                  onClose={() => {
                    setDisplayPopupPassword(false);
                  }}
                  showCross={true}
                  customClass="max-w-[347px]"
                  popup={displayPopupPassword}
                >
                  <div className="text-headerBlue text-[20px] flex items-start">
                    Reset Partner Password?
                  </div>

                  <ResetPasswordPartner
                    partnerInfo={partnerInfo as IPartnerResult}
                    onClose={() => {
                      setDisplayPopupPassword(false);
                    }}
                  />
                </GlimpsePopup>
              </div>
            </div>
          </div>
          <div>
            <Tabs
              items={tabsList}
              onClick={tabChanged}
              activeIndex={activeTab}
              className="flex"
            />
          </div>

          {activeTab === 0 && (
            <div className="grid grid-cols-1 gap-3 p-2 lg:grid-cols-2 xl:grid-cols-3 ">
              <GlimpseSmallCard title="Total Licenses">
                <div className="relative">
                  <div
                    className="absolute px-2 py-[2px] border rounded-full border-headerBlue h[18px] right-0 bottom-[10px] text-[10px] cursor-pointer"
                    onClick={() => {
                      setDisplayPopup(true);
                    }}
                  >
                    Add Licenses
                  </div>
                </div>
                <span>{partnerInfo.total_licenses}</span>
              </GlimpseSmallCard>
              <GlimpseSmallCard title="Assigned Licenses">
                <span>
                  {partnerInfo.assigned_licence_count
                    ? partnerInfo.assigned_licence_count
                    : 0}
                </span>
              </GlimpseSmallCard>
              <GlimpseSmallCard title="Weekly Usage">
                <span className="pr-2">
                  {partnerInfo.total_weekly_hours
                    ? partnerInfo.total_weekly_hours
                    : 0}
                </span>
                hrs
              </GlimpseSmallCard>
              <GlimpseSmallCard title="Earned Bronze Level">
                <div className="relative flex justify-between">
                  <span>
                    {partnerInfo.bronze_badge_count
                      ? partnerInfo.bronze_badge_count
                      : 0}
                  </span>
                  <span className="absolute right-0 h-16 -top-[10px]">
                    <DughnutComponent
                      centerPercentage={true}
                      centerValue={`${earnedBronzePercentage.toFixed(0)}%`}
                      data={chartDataBronzeEarned}
                      fontStyle="400 10px sans-serif"
                    />
                  </span>
                </div>
              </GlimpseSmallCard>
              <GlimpseSmallCard title="Assigned Top 3">
                <div className="relative flex justify-between">
                  <span>
                    {partnerInfo.top_three_assigned_users
                      ? partnerInfo.top_three_assigned_users
                      : 0}
                  </span>
                  <span className="absolute right-0 h-16 -top-[10px]">
                    <DughnutComponent
                      centerPercentage={true}
                      centerValue={`${topThreePercentage.toFixed(0)}%`}
                      data={chartDataTopthree}
                      fontStyle="400 10px sans-serif"
                    />
                  </span>
                </div>
              </GlimpseSmallCard>
            </div>
          )}
          {activeTab === 1 && (
            <div className="flex flex-col gap-3 p-3 overflow-auto w-100">
              <GlimpseTable
                columns={individualsMembersColumns}
                data={partnerMemberList ? partnerMemberList.results : []}
                totalData={partnerMemberList?.count as number}
                currentPage={membersPagination.page}
                totalPage={
                  partnerMemberList
                    ? Math.ceil(
                        partnerMemberList.count / membersPagination.page_size
                      )
                    : 0
                }
                enableFilter={true}
                enableExport={true}
                enableSearch={true}
                enablePagination={true}
                preventClickOn={["id"]}
                loadingData={isPartnerMemberListLoading}
                filterItems={filterItems}
                filterChanged={async (data, key) => {
                  setFilterItems(data);
                  let values = data.flatMap((group) =>
                    group.filters.filter((filter) => filter.is_checked)
                  );
                  await setmembersPagination((prevData) => ({
                    ...prevData,
                    status: "",
                    top_three: "",
                  }));
                  values.map(async (item) => {
                    if (
                      item.name === StatusFilter.Active ||
                      item.name === StatusFilter.Invited
                    ) {
                      await setmembersPagination((prevData) => ({
                        ...prevData,
                        page: 1,
                        status:
                          item.name === StatusFilter.Active ||
                          item.name === StatusFilter.Invited
                            ? item.name
                            : "",
                      }));
                    }

                    if (
                      item.name ===PaginationFilter.ten ||
                      item.name === PaginationFilter.default || item.name === PaginationFilter.fifty 
                    ) {
                      setmembersPagination((prevData) => ({
                        ...prevData,
                        page: 1,
                        page_size: item.name === PaginationFilter.ten ||
                          item.name === PaginationFilter.default || item.name === PaginationFilter.fifty
                          ? item.name
                          : 25,
                      }));
                    }
      




                    if (
                      item.name === Top3Filter.Assigned ||
                      item.name === Top3Filter.Unassigned
                    ) {
                      await setmembersPagination((prevData) => ({
                        ...prevData,
                        page: 1,
                        top_three:
                          item.name === Top3Filter.Assigned ||
                          item.name === Top3Filter.Unassigned
                            ? item.name
                            : "",
                      }));
                    }
                  });
                }}
                rowSelected={() => {}}
                onSearch={async (data) => {
                  await setmembersPagination((prevData) => ({
                    ...prevData,
                    search: data.target.value,
                    page: 1,
                    page_size: 25,
                  }));
                  // refetchMembersList();
                }}
                nextPage={async () => {
                  if (
                    Math.ceil(
                      partnerMemberList
                        ? partnerMemberList.count / membersPagination.page_size
                        : 0
                    ) <= membersPagination.page
                  ) {
                    displayWarning("You are at end of the page");
                  } else {
                    await setmembersPagination((prevData) => ({
                      ...prevData,
                      page: prevData.page + 1,
                    }));
                    refetchMembersList();
                  }
                }}
                previousPage={async () => {
                  if (membersPagination.page <= 1) {
                    displayWarning("You are already in page one.");
                  } else {
                    await setmembersPagination((prevData) => ({
                      ...prevData,
                      page: prevData.page - 1,
                    }));
                    refetchMembersList();
                  }
                }}
                exportData={() => {
                  downloadReport.mutateAsync({
                    search: membersPagination.search
                      ? (membersPagination.search as string)
                      : null,
                    status: membersPagination.status
                      ? membersPagination.status
                      : null,
                    top_three: membersPagination.top_three
                      ? membersPagination.top_three
                      : null,
                    partner_id: partnerInfo.id,
                  });
                }}
              />
            </div>
          )}
          {activeTab === 2 && (
            <div className="flex flex-col gap-3 p-3 overflow-auto w-100 ">
              <div className="text-[16px] leading-6 ">
                <div>Number of Licenses</div>
                <span>{partnerInfo?.total_licenses}</span>
              </div>
              <div className="name">
                <div>Account Type</div>
                <span className="capitalize">{partnerInfo?.type}</span>
              </div>
              <div className="name">
                <div>Account Creation Date</div>
                <span>
                  {partnerInfo
                    ? new Date(partnerInfo?.user.date_joined).toDateString()
                    : null}
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          <NotFound
            isHome={false}
            message="Sorry, we couldn't find the user information you're looking for."
            title="Not Found"
          />
        </div>
      )}
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopupPasswordMember(false);
        }}
        showCross={true}
        customClass="max-w-[347px]"
        popup={displayPopupPasswordMember}
      >
        <div className="text-headerBlue text-[20px] flex items-start">
          Reset Member Password?
        </div>
        {popupMemberData && (
          <ResetPasswordMember
            memberInfo={popupMemberData as IRowItem}
            onClose={() => {
              setDisplayPopupPasswordMember(false);
            }}
          />
        )}
      </GlimpsePopup>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDeleteAlert(false);
        }}
        showCross={false}
        customClass="max-w-[347px]"
        popup={deleteAlert}
      >
        {popupMemberData && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Delete Member Account
            </div>
            <div>
              If you delete
              <span className="px-1 font-bold">
                {popupMemberData.member.name}'s
              </span>
              membership, their account data will be permanently deleted. This
              action cannot be undone.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setDeleteAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-red w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleDeleteMemberSubmit(popupMemberData);
                  setDeleteAlert(false);
                }}
              >
                Sure Delete
              </button>
            </div>
          </>
        )}
      </GlimpsePopup>
    </>
  );
};

export default PartnerInsightScreen;
