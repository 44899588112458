import GlimpseSmallCard from "components/card/smallCard.component";
import DughnutComponent from "components/charts/doughnut.component";
import GlimpsePopup from "components/popup/popup.component";
import GlimpseTable from "components/table/table.component";
import { ChangeEvent, useEffect, useState } from "react";
import AddPartner from "./addPartner";
import { useNavigate } from "react-router";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addNewPartner,
  deletePartner,
  getAllPartners,
  getPartnerDashboardData,
  updatePartnerInfo,
} from "services/partners.service";
import { IPartnerResult } from "interfaces/AddPartnersInterface";
import { PaginationFilter, toggleEnum } from "enums/glimps.enums";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import { IDownloadReport, IPagination } from "interfaces/BaseInterface";
import { downloadReportFilePartners } from "services/members.service";
import ResetPasswordPartner from "components/reset-password/resetPasswordPartner";
import { IFilterArray, IFilterData } from "components/table/table.interface";
import Dropdown from "components/dropdown/dropdown.component";
import TimeAgo from "utills/time-ago";

const initialPartnerInfo: IPartnerResult = {
  name: "",
  contact_person: "",
  contact_email: "",
  type: toggleEnum.paid,
  total_licenses: 0,
  model_quiz: true,
  // date_of_birth:'2024-11-20T08:21:51.500Z'
};

const filterItemsDatas = [
  
      
    {
        label:"Entries per page",
        filters: [{
          label:"10",
          name:10 ,
    
          is_checked:false,
        },
        {
          label:"25",
          name:25,
    
    
    
          is_checked:true,
    
        },
        {
            label:"50",
            name:50,
    
      
      
            is_checked:false,
      
          }
    ]
    },
    
  
  {
    label: "Type",
    filters: [
      { name: toggleEnum.paid, label: "Paid", is_checked: false },
      { name: toggleEnum.comped, label: "comped", is_checked: false },
    ],
  },
];

const PartnerScreen: React.FC = () => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [filterItems, setFilterItems] =
    useState<IFilterArray[]>(filterItemsDatas);
  const [partnerInfo, setPartnerInfo] =
    useState<IPartnerResult>(initialPartnerInfo);
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IPartnerResult>();
  const [displayPopupPassword, setDisplayPopupPassword] = useState(false);
  const [popupMemberData, setPopupMemberData] = useState<IPartnerResult | null>(
    null
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const [partnerPagination, setPartnerPagination] = useState<IPagination>({
    page: 1,
    page_size: 25,
    search: "",
    top_ten: false,
    type: "",
    report: "",
    ordering: "",
  });

  const handleDeletePartner = useMutation({
    mutationFn: (data: string) => deletePartner(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Partner has been deleted successfully.");
      refetchPartners();
    },
    onError: () => {
      displayError("Unable to delete partner.");
    },
  });

  const handleDeletePartnerSubmit = (values: IPartnerResult) => {
    handleDeletePartner.mutateAsync(values.id as string);
  };

  // add new partner
  const registerPartner = useMutation({
    mutationFn: (partners: IPartnerResult) => {
      if (partners.id) {
        return updatePartnerInfo(partners);
      } else {
        return addNewPartner(partners);
      }
    },
    retry: 0,
    onSuccess: (partners: IPartnerResult) => {
      displaySuccess(
        `${!partners.id
          ? `${partners.name} Information has been updated successfully.`
          : "New partner has been added successfully."
        }`
      );
      refetchPartners();
      setPartnerInfo(initialPartnerInfo);
      setDisplayPopup(false);
    },
    onError: (partners: IPartnerResult) => {
      displayError(`Unable to ${!partners.id ? "Update " : "Add"} Partner`);
    },
  });

  const isPartnerDialogLoading = registerPartner.isPending;

  const {
    data: partnerList,
    isLoading: isPartnerListLoading,
    status: partnerFetchStatus,
    refetch: refetchPartners,
    isFetching,
  } = useQuery({
    queryKey: ["GetAllPartners"],
    queryFn: () => {
      return getAllPartners({
        ...partnerPagination,
      });
    },
    enabled: !!partnerPagination,
    // enabled: true,
    refetchOnWindowFocus: false,
    retry: 0,
    initialData: null,
  });

  useEffect(() => {
    if (sorting.length) {
      let sortData = sorting[0].id;
      if (sortData.endsWith("_count")) {
        sortData = sortData.slice(0, -6);
      }
      setPartnerPagination((prev) => ({
        ...prev,
        ordering: sorting[0].desc ? `-${sortData}` : sortData,
      }));
    } else {
      setPartnerPagination((prev) => ({ ...prev, ordering: "" }));
    }
  }, [sorting]);

  useEffect(() => {
    refetchPartners();
  }, [partnerPagination]);

  const partnerTableColumn = [
    columnHelper.accessor("name", {
      header: () => "Partner",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("total_licenses", {
      header: () => "Total Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("assigned_licence_count", {
      header: () => "Assigned Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("active_user_count", {
      header: () => "Activated Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("type", {
      header: () => "type",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
    columnHelper.accessor("last_active", {
      header: () => "Last Active",
      cell: (info) =>
        info.getValue() ? (
          <span className="capitalize text-nowrap">
            <TimeAgo date={new Date(info.getValue() as string)} />
          </span>
        ) : (
          "--"
        ),
    }),
    // columnHelper.accessor("date_of_birth", {
    //   header: () => "Date Of Birth",
    //   cell: (info) => {         
    //     const value= info.getValue()||''
    //     const sampleDate=new Date(value)

    //     return<span className="capitalize">{sampleDate?.toLocaleDateString()}</span>},
    // }),
    columnHelper.accessor("id", {
      header: "",
      maxSize: 12,
      cell: (info) => {
        const dropdownItems = [
          {
            label: "Send Email",
            onClick: () =>
              (window.location.href = `mailto:${info.row.original.contact_email}`),
          },
          {
            label: "Edit Account",
            onClick: () => {
              setDisplayPopup(true);
              setPartnerInfo(() => info.row.original);
            },
          },
          {
            label: "Reset Password",
            onClick: () => {
              setDisplayPopupPassword(true);
              setPopupMemberData(info.row.original);
            },
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];
        return (
          <>
            <Dropdown
              buttonText=""
              onOpened={(data) => {
              }}
              items={dropdownItems}
              tableComponent={true}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#414346"
                  />
                </svg>
              }
            />
          </>
        );
      },
    }),
  ];

  // download
  const downloadReport = useMutation({
    mutationFn: (reportParams: IDownloadReport) =>
      downloadReportFilePartners(reportParams),
    mutationKey: ["DownloadReport"],
    retry: 0,
    onSuccess: (data: Blob) => {
      const link: HTMLElement = document.createElement("a");
      const blob: Blob = new Blob([data]);
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute(
        "download",
        `Partner's ${new Date().toDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });
  // getPartnerDashboardData
  const { data: partnerDashboardData } = useQuery({
    queryKey: ["partnerDashboardData"],
    queryFn: () => getPartnerDashboardData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // OVERVIEW CHART PERCENTAGE
  const earnedBadgePercenrtage =
    partnerDashboardData &&
      partnerDashboardData.earned_badge !== 0 &&
      partnerDashboardData.total_partner_users_count !== 0
      ? (partnerDashboardData.earned_badge /
        partnerDashboardData.total_partner_users_count) *
      100
      : 0;

  const assignedTop3 =
    partnerDashboardData &&
      partnerDashboardData.top_three_assigned !== 0 &&
      partnerDashboardData.total_partner_users_count !== 0
      ? (partnerDashboardData.top_three_assigned /
        partnerDashboardData.total_partner_users_count) *
      100
      : 0;

  const chartDataBadge = {
    datasets: [
      {
        data: [earnedBadgePercenrtage, 100 - earnedBadgePercenrtage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };

  const chartDataTop3 = {
    datasets: [
      {
        data: [assignedTop3, 100 - assignedTop3],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  const sampleDate=new Date('2024-11-20T08:21:51.500Z')

  console.log(sampleDate?.toLocaleDateString(),'sample dob')
  return (
    <>
      <div className="flex justify-between">
        <div className="overview font-normal text-[20px] leading-6 ">
          Overview
        </div>
        <div className="addpartners">
          <button
            type="button"
            onClick={() => {
              setDisplayPopup(true);
            }}
            className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
          >
            Add Partner
          </button>
        </div>
      </div>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopup(false);
          setPartnerInfo(initialPartnerInfo);
        }}
        showCross={false}
        customClass="max-w-[347px]"
        popup={displayPopup}
      >
        <AddPartner
          partnerInfo={partnerInfo}
          isLoading={isPartnerDialogLoading}
          onSubmit={(data) => {
            registerPartner.mutateAsync(data);
          }}
          onClose={() => {
            setDisplayPopup(false);
            setPartnerInfo(initialPartnerInfo);
          }}
        />
      </GlimpsePopup>
      {partnerDashboardData && (
        <div className="flex justify-between gap-5 p-2 overflow-auto">
          <GlimpseSmallCard title="Organizational Partners">
            <span className="text-[24px] text-green font-semibold">
              {partnerDashboardData.partners_count}
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Weekly Usage">
            <span className="text-[24px] text-green font-semibold">
              {partnerDashboardData.total_weekly_hours}
              <span className="pl-2">hrs</span>
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Earned Bronze Level">
            <div className="relative flex justify-between">
              <span className="text-[24px] text-green font-semibold">
                {partnerDashboardData.earned_badge}
              </span>
              <span className="absolute right-0 h-16 -top-[10px]">
                <DughnutComponent
                  centerPercentage={true}
                  centerValue={`${earnedBadgePercenrtage.toFixed(0)}%`}
                  fontStyle="400 12px sans-serif"
                  data={chartDataBadge}
                />
              </span>
            </div>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Assigned Top 3">
            <div className="relative flex justify-between">
              <span className="text-[24px] text-green font-semibold">
                {partnerDashboardData.top_three_assigned}
              </span>
              <span className="absolute right-0 h-16 -top-[10px]">
                <DughnutComponent
                  centerPercentage={true}
                  centerValue={`${assignedTop3.toFixed(0)}%`}
                  fontStyle="400 12px sans-serif"
                  data={chartDataTop3}
                />
              </span>
            </div>
          </GlimpseSmallCard>
        </div>
      )}
      <div className="relative flex flex-col gap-3 p-3 w-100">
        {!isPartnerListLoading && partnerFetchStatus === "success" ? (
          <GlimpseTable
            columns={partnerTableColumn}
            data={partnerList ? partnerList.results : []}
            totalData={partnerList?.count as number}
            currentPage={partnerPagination.page}
            isPartner={true}
            totalPage={
              partnerList
                ? Math.ceil(partnerList.count / partnerPagination.page_size)
                : 0
            }
            enableFilter={true}
            enableExport={true}
            enableSearch={true}
            enablePagination={true}
            preventClickOn={["id"]}
            sorting={sorting}
            setSorting={(sortingData) => {
              setSorting(sortingData);
            }}
            loadingData={isFetching}
            filterItems={filterItems}
            filterChanged={async (data, key) => {
              setFilterItems(data);
              let values = data.flatMap((group) =>
                group.filters.filter((filter) => filter.is_checked)
              );
 
              // await setPartnerPagination((prevData) => ({
              //   ...prevData,
              //   page: 1,
              //   type: key,
              // }));

              values.map(async (item) => {
                if (
                  item.name ===PaginationFilter.ten ||
                  item.name === PaginationFilter.default || item.name === PaginationFilter.fifty 
                ) {
                  setPartnerPagination((prevData) => ({
                    ...prevData,
                    page: 1,
                    page_size: item.name === PaginationFilter.ten ||
                      item.name === PaginationFilter.default || item.name === PaginationFilter.fifty
                      ? item.name
                      : 25,
                  }));
                }
  
                if (
                  item.name ===toggleEnum.paid ||
                  item.name === toggleEnum.comped 
                ) {
                  setPartnerPagination((prevData) => ({
                    ...prevData,
                    page: 1,
                    type: item.name ===toggleEnum.paid ||
                    item.name === toggleEnum.comped 
                      ? item.name
                      : '',
                  }));
                }
  
           
  
  
  
  
  
                
              });





            }}
            rowSelected={(data) => {
              navigate(`/partners/partners-insight/${data?.id}`);
            }}
            onSearch={async (data) => {
              await setPartnerPagination((prevData) => ({
                ...prevData,
                page: 1,
                search: data.target.value,
              }));
              // refetchPartners();
            }}
            nextPage={async () => {
              if (
                Math.ceil(
                  partnerList
                    ? partnerList.count / partnerPagination.page_size
                    : 0
                ) <= partnerPagination.page
              ) {
                displayWarning("You are at end of the page");
              } else {
                await setPartnerPagination((prevData) => ({
                  ...prevData,
                  page: prevData.page + 1,
                }));
                // refetchPartners();
              }
            }}
            previousPage={async () => {
              if (partnerPagination.page <= 1) {
                displayWarning("You are already in page one.");
              } else {
                await setPartnerPagination((prevData) => ({
                  ...prevData,
                  page: prevData.page - 1,
                }));
                // refetchPartners();
              }
            }}
            exportData={() => {
              downloadReport.mutateAsync({
                search: partnerPagination.search
                  ? (partnerPagination.search as string)
                  : null,
                // type: paginationFromReducer.type.toLowerCase(),
                // todo fix filteritems
              });
            }}
          />
        ) : (
          "Loading"
        )}
      </div>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDeleteAlert(false);
        }}
        showCross={false}
        customClass="max-w-[347px]"
        popup={deleteAlert}
      >
        {popupMemberData && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Delete Partner Account
            </div>
            <div>
              If you delete
              <span className="px-1 font-bold">{popupMemberData.name}'s</span>
              partnership, their account data will be permanently deleted. This
              action cannot be undone.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setDeleteAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-red w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleDeletePartnerSubmit(popupMemberData);
                  setDeleteAlert(false);
                }}
              >
                Sure Delete
              </button>
            </div>
          </>
        )}
      </GlimpsePopup>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopupPassword(false);
          setPopupMemberData(null);
        }}
        showCross={true}
        customClass="max-w-[347px]"
        popup={displayPopupPassword}
      >
        {popupMemberData && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Reset Partner Password?
            </div>

            <ResetPasswordPartner
              partnerInfo={popupMemberData}
              onClose={() => {
                setDisplayPopupPassword(false);
              }}
            />
          </>
        )}
      </GlimpsePopup>
    </>
  );
};

export default PartnerScreen;
