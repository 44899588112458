import { PaginationFilter, StatusFilter, toggleEnum, Top3Filter } from "enums/glimps.enums";

export const filterItemsDatas = [
    
    {
        label:"Entries per page",
        filters: [{
          label:"10",
          name:10 ,
    
          is_checked:false,
        },
        {
          label:"25",
          name:25,
    
    
    
          is_checked:true,
    
        },
        {
            label:"50",
            name:50,
    
      
      
            is_checked:false,
      
          }
    ]
    },
    
    
    {
        label: "Status",
        filters: [
            { name: StatusFilter.Active, label: "Active", is_checked: false },
            { name: StatusFilter.Invited, label: "Invited", is_checked: false },
        ],
    },
    {
        label: "Top 3",
        filters: [
            { name: Top3Filter.Assigned, label: "Assigned", is_checked: false },
            { name: Top3Filter.Unassigned, label: "Unassigned", is_checked: false },
        ],
    },
];