import GlimpseCard from "../../components/card/card.component";
import GlimpseTable from "../../components/table/table.component";
import BarChart from "../../components/charts/bar-chart.component";
import { createColumnHelper } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  getAllPartners,
  getBarDailyUsuage,
  getCareerBadgesAdmin,
  getDashboardData,
  getDoughnutDailyUsuage,
  getMostPopularCareer,
} from "services/partners.service";
import { IPartnerResult } from "interfaces/AddPartnersInterface";
import GlimpseSmallCard from "components/card/smallCard.component";
import DughnutComponent from "components/charts/doughnut.component";
import Tabs from "components/tabs/tabs.component";
import { ITabItem } from "components/tabs/tabs.interface";
import { IPagination } from "interfaces/BaseInterface";
import {
  IBadgeReport,
  IBarData,
  IBarDataGet,
  IMostPopularGlimpse,
  MembershipData,
} from "interfaces/dashboard.interface";
import WeekRangePicker from "components/week-range-picker/weekPicker.component";
import { ChartData } from "chart.js";
import { useNavigate } from "react-router-dom";

const tabsList: ITabItem[] = [
  { name: "Overall" },
  { name: "Partners" },
  { name: "Individuals" },
];

const AdminDashboardScreen: React.FC = () => {
  const columnHelper = createColumnHelper<IPartnerResult>();
  const navigation = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [partnerPagination, setPartnerPagination] = useState<IPagination>({
    page: 1,
    page_size: 25,
    top_ten: false,
    search: "",
    type: "",
  });

  const [currentWeek, setCurrentWeek] = useState<Date>(new Date());

  useEffect(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diffStart = today.getDate() - dayOfWeek;
    // const diffEnd = diffStart + 6;
    const weekStart = new Date(today.setDate(diffStart));

    setCurrentWeek(weekStart);
  }, []);

  const dailyUsage: IBarDataGet = {
    start_date: currentWeek.toISOString().split("T")[0],
    user_type:
      activeTab === 0 ? "" : activeTab === 1 ? "partners" : "individuals",
  };

  const {
    data: partnerList,
    isLoading: isPartnerListLoading,
    refetch,
  } = useQuery({
    queryKey: ["GetAllPartners", partnerPagination],
    queryFn: () => getAllPartners(partnerPagination),
    enabled: !!partnerPagination,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const partnerTableColumn = [
    columnHelper.accessor("name", {
      header: () => "Partner",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("total_licenses", {
      header: () => "Total Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("assigned_licence_count", {
      header: () => "Assigned Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("active_user_count", {
      header: () => "Activated Licenses",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("type", {
      header: () => "type",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
  ];

  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
  };

  const { data: dailyUsageData, status } = useQuery({
    queryKey: ["barDailyUsuage", dailyUsage],
    queryFn: () => getBarDailyUsuage(dailyUsage),
    enabled: !!dailyUsage,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const label = dailyUsageData?.daily_usage.map((i: IBarData) => {
    return [i.date.day, i.date.date];
    // return [i.date.day.split("day")[0], i.date.date];
  });

  const barData: ChartData<"bar"> = {
    labels: label,
    datasets: [
      {
        label: "Time Spent",
        data:
          (dailyUsageData &&
            dailyUsageData.daily_usage.map((d) => d.time_spend)) ??
          [],
        backgroundColor: "#B0675A",
        borderRadius: {
          topLeft: 8,
          topRight: 8,
        },
      },
    ],
  };

  const { data: dailyUsageDataDoughnut } = useQuery({
    queryKey: ["doughnutDailyUsuage"],
    queryFn: () => getDoughnutDailyUsuage(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const chartData: ChartData<"doughnut"> = {
    labels: dailyUsageDataDoughnut?.membership_data?.map(
      (d: MembershipData) => d.label
    ),
    datasets: [
      {
        data: dailyUsageDataDoughnut?.membership_data.map((d) => d.value) ?? [],
        backgroundColor: dailyUsageDataDoughnut?.membership_data?.map(
          (d) => d.color
        ),
      },
    ],
  };

  const { data: topGlimpse } = useQuery({
    queryKey: ["topGlimpse"],
    queryFn: () => getMostPopularCareer(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: iqLevel } = useQuery({
    queryKey: ["iqlevel"],
    queryFn: () => getCareerBadgesAdmin(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: dashboardData } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: () => getDashboardData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  return (
    <>
      {dashboardData && (
        <div className="flex justify-between gap-5 p-2 overflow-auto">
          <GlimpseSmallCard title={"Total Licenses"} className="min-w-[160px]">
            <span className="text-[24px] text-green font-semibold">
              {dashboardData.total_membership}
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard
            title={"Total Weekly Hours"}
            className="min-w-[160px]"
          >
            <span className="text-[24px] text-green font-semibold">
              {dashboardData.total_weekly_hours}
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title={"Shared Top 3"} className="min-w-[160px]">
            <span className="text-[24px] text-green font-semibold">
              {dashboardData.shared_top_three}
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title={"Shared Top 10"} className="min-w-[160px]">
            <span className="text-[24px] text-green font-semibold">
              {dashboardData.shared_top_ten}
            </span>
          </GlimpseSmallCard>
        </div>
      )}

      <div className="flex flex-col gap-3 p-3 w-100">
        <p className="text-xl font-semibold leading-6">Activity</p>
        <div className="flex flex-row gap-5 p-2 overflow-auto">
          <GlimpseCard
            title="Daily Usage"
            className="min-w-[318px] max-w-[352px] h-[410px] relative overflow-hidden"
            childrenClass="h-full"
          >
            <div className="absolute flex justify-end top-2 right-2">
              <WeekRangePicker
                currentWeek={currentWeek}
                setCurrentWeek={setCurrentWeek}
              />
            </div>
            <div className="w-full overflow-auto ">
              <Tabs
                items={tabsList}
                onClick={tabChanged}
                activeIndex={activeTab}
                className="inline-table"
                tabFontSize="text-sm"
                opacity={false}
              />
            </div>
            <div className="h-[290px]">
              {barData && <BarChart data={barData} key={"AdminBarData"} />}
            </div>
          </GlimpseCard>
          <GlimpseCard
            title="Membership Type"
            className="min-w-[318px] max-w-[352px] !h-[395px]"
            childrenClass="h-full"
          >
            <div className="min-w-[227px] h-[227px] flex justify-center items-center">
              {dailyUsageDataDoughnut && chartData && (
                <DughnutComponent
                  data={chartData}
                  centerPercentage={true}
                  centerValue={
                    dailyUsageDataDoughnut?.total_users
                      ? dailyUsageDataDoughnut.total_users
                      : "Total Membership 0"
                  }
                  showDataLabels={true}
                  totalCount={
                    dailyUsageDataDoughnut?.total_users
                      ? parseInt(
                        dailyUsageDataDoughnut?.total_users.split(
                          "Total Memberships"
                        )[1]
                      )
                      : 0
                  }
                  fontStyle="400 12px sans-serif"
                />
              )}
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col gap-2 pt-3">
                {dailyUsageDataDoughnut?.membership_data?.map((data, index) => {
                  return (
                    <span className="flex items-center gap-2" key={index}>
                      <span
                        className={`rounded-full w-[16px] h-[16px] bg-[${data.color}] `}
                        style={{
                          background: `${data.color}`,
                        }}
                      ></span>
                      <span className="text-xs">
                        {data.label} - {data.value}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
          </GlimpseCard>
          <div className="min-w-[335px] min-h-[371px] flex flex-col gap-4">
            <GlimpseCard
              title="Career Awareness Levels"
              className="min-w-[335px] h-[197px]"
              childrenClass="flex justify-center items-center gap-3 pt-4"
            >
              {iqLevel?.map((item: IBadgeReport, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center text-sm text-center text-headerBlue"
                  >
                    <span className="px-2 py-2">
                      <img
                        src={`/assets/icons/${item.slug}_badge.png`}
                        alt="badge"
                        className="w-[70px] h-auto"
                      />
                    </span>
                    <span className="tracking-wider capitalize">
                      {`${item.slug}`}
                    </span>
                    <span className="font-bold">{item.badge_user_count}</span>
                  </div>
                );
              })}
            </GlimpseCard>

            <GlimpseCard
              title="Most Popular Glimpses"
              className="min-w-[335px] h-[197px]"
              childrenClass="flex justify-between items-center pt-4"
            >
              {topGlimpse?.length === 0 ? (
                <span> Data Not Available </span>
              ) : (
                <>
                  {topGlimpse?.map((item: IMostPopularGlimpse, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-center text-center text-[10px] text-headerBlue "
                      >
                        <span className="px-2 py-2">
                          <img
                            src={item.icon_url}
                            alt={`top_glimpses_${item.name}`}
                            className="w-[70px] h-auto"
                          />
                        </span>
                        <span className={`tracking-wider`}>{item.name}</span>
                      </div>
                    );
                  })}
                </>
              )}
            </GlimpseCard>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 p-3 overflow-auto overflow-y-hidden w-100">
        <p className="text-xl font-semibold leading-6">Top 10 Partners</p>
        <GlimpseTable
          columns={partnerTableColumn}
          data={partnerList ? partnerList.results : []}
          loadingData={isPartnerListLoading}
          totalData={partnerList?.count as number}
          currentPage={partnerPagination.page}
          totalPage={
            partnerList
              ? Math.ceil(partnerList.count / partnerPagination.page_size)
              : 0
          }
          enableFilter={false}
          enableExport={false}
          enableSearch={false}
          enablePagination={false}
          preventClickOn={["id"]}
          rowSelected={(data) => {
            navigation(`/partners/partners-insight/${data?.id}`);
          }}
          onSearch={() => { }}
          filterChanged={() => { }}
        />
      </div>
    </>
  );
};

export default AdminDashboardScreen;
