// export default PartnerDashboard;

import GlimpseTable from "../../components/table/table.component";
import { createColumnHelper } from "@tanstack/react-table";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { IReinviteMember } from "interfaces/AddPartnersInterface";
import GlimpseSmallCard from "components/card/smallCard.component";
import {
  deleteInviteMember,
  deleteMember,
  downloadReportFile,
  getAllMembers,
  reInviteMember,
} from "services/members.service";
import { IDownloadReport, IPagination } from "interfaces/BaseInterface";
import { useNavigate } from "react-router-dom";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import GlimpsePopup from "components/popup/popup.component";
import GlimpseAddMember from "components/add-partners-member/addMember.component";
import { ImemberInfo } from "interfaces/MembersInterface";
import { getUserPartnerDashboardData } from "services/partners.service";
import DughnutComponent from "components/charts/doughnut.component";
import { BadgesCount } from "interfaces/dashboard.interface";
import ResetPasswordMember from "components/reset-password/resetPasswordMember";
import {
  IFilterArray,
  ITopThreeCareer,
} from "components/table/table.interface";
import Dropdown from "components/dropdown/dropdown.component";
import { filterItemsDatas } from "utills/Filter-Options";
import { PaginationFilter, StatusFilter, Top3Filter } from "enums/glimps.enums";

const PartnerDashboard: React.FC = () => {
  const [filterItems, setFilterItems] =
    useState<IFilterArray[]>(filterItemsDatas);
  const columnHelper = createColumnHelper<ImemberInfo>();
  const [displayAddmember, setDisplayAddmember] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [displayPopupPasswordMember, setDisplayPopupPasswordMember] =
    useState(false);
  const [popupMemberData, setPopupMemberData] = useState<ImemberInfo | null>(
    null
  );
  const [partnerPagination, setPartnerPagination] = useState<IPagination>({
    page: 1,
    page_size: 25,
    top_ten: false,
    search: "",
    status: "",
    top_three: "",
    dob:'',
  });

  const navigation = useNavigate();

  const { data: partnerList, isLoading: isPartnerListLoading } = useQuery({
    queryKey: ["GetAllPartnerMembers", partnerPagination],
    queryFn: () => getAllMembers(partnerPagination),
    enabled: !!partnerPagination,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // download
  const downloadReport = useMutation({
    mutationFn: (reportParams: IDownloadReport) =>
      downloadReportFile(reportParams),
    mutationKey: ["DownloadReport"],
    retry: 0,
    onSuccess: (data: Blob) => {
      const link: HTMLElement = document.createElement("a");
      const blob: Blob = new Blob([data]);
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute(
        "download",
        `Individual members ${new Date().toDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  // reinvitation
  const reSendInvitationForMember = useMutation({
    mutationFn: (data: IReinviteMember) => reInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member reinvitation has been sent successfully");
    },
    onError: () => {
      displayError("Unable to reinvite member.");
    },
  });

  const handleReinvitationSubmit = (email: string) => {
    const payload = { email: email };
    reSendInvitationForMember.mutateAsync(payload);
  };

  const handleDeleteMember = useMutation({
    mutationFn: (data: string) => deleteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Partner has been deleted successfully.");
    },
    onError: () => {
      displayError("Unable to delete partner.");
    },
  });

  const handleDeleteInvitedMember = useMutation({
    mutationFn: (data: string) => deleteInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Partner has been deleted successfully.");
    },
    onError: () => {
      displayError("Unable to delete partner.");
    },
  });

  const handleDeleteMemberSubmit = (values: ImemberInfo) => {
    if (values.status === "active") {
      handleDeleteMember.mutateAsync(values.id);
    } else {
      handleDeleteInvitedMember.mutateAsync(values.id);
    }
  };

  //table
  const partnerTableColumn = [
    columnHelper.accessor("member.name", {
      header: () => "Member",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.top_three, {
      id: "top_three",
      cell: (info) => (
        <>
          <div className="flex gap-1">
            {info.getValue()
              ? info.getValue().map((item: ITopThreeCareer, index: number) => {
                  return (
                    <img
                      key={index}
                      className="min-w-[24px] h-[24px]"
                      src={item.icon_url || ""}
                      alt={`Career Icon ${index + 1}`}
                      title={item.name}
                    />
                  );
                })
              : ""}
          </div>
        </>
      ),
      header: () => <span>Top Three</span>,
    }),
    columnHelper.accessor("videos_watched", {
      header: () => "Video Previews Watched",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("modules_completed", {
      header: () => "Deeper Dives Completed",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("career_ig_level", {
      header: "Career Awareness",
      enableSorting: false,
      cell: (info) =>
        info.getValue() && (
          <img
            className="w-[24px] h-[24px]"
            src={`/assets/icons/${info
              .getValue()
              ?.split(" ")[0]
              .toLowerCase()}_badge.png`}
            alt={info.getValue() || "career_three_icon"}
          />
        ),
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
    columnHelper.accessor("last_active", {
      header: () => "Last Active",
      cell: (info) => (
        <span>
          {info.getValue() ? new Date(info.getValue()).toDateString() : "--"}
        </span>
      ),
    }),
    columnHelper.accessor("dob", {
      header: "Birth Year",
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
    columnHelper.accessor("id", {
      header: "",
      maxSize: 12,
      cell: (info) => {
        const dropdownActivetableItems = [
          {
            label: "Send Email",
            onClick: () =>
              (window.location.href = `mailto:${info.row.original.email}`),
          },
          {
            label: "Reset Password",
            onClick: () => {
              setDisplayPopupPasswordMember(true);
              setPopupMemberData(info.row.original);
            },
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];

        const dropdownInvitedtableItems = [
          {
            label: "Resend Invite",
            onClick: () => handleReinvitationSubmit(info.row.original.email),
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];
        return (
          <>
            <Dropdown
              buttonText=""
              onOpened={(data) => {}}
              items={
                info.row.original.status === "active"
                  ? dropdownActivetableItems
                  : dropdownInvitedtableItems
              }
              tableComponent={true}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#414346"
                  />
                </svg>
              }
            />
          </>
        );
      },
    }),
  ];

  const { data: userPartnerDashboardData } = useQuery({
    queryKey: ["userPartnerDashboardData"],
    queryFn: () => getUserPartnerDashboardData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const assignedTop3 =
    userPartnerDashboardData &&
    userPartnerDashboardData.top_three_assigned_users !== 0 &&
    userPartnerDashboardData.assigned_users !== 0
      ? (userPartnerDashboardData.top_three_assigned_users /
          userPartnerDashboardData.assigned_users) *
        100
      : 0;

  const chartDataTop3 = {
    datasets: [
      {
        data: [assignedTop3, 100 - assignedTop3],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  return (
    <>
      <div className="flex justify-between gap-5 p-2 overflow-auto">
        <p>Overview</p>
        <button
          type="button"
          name="Add_Members"
          onClick={() => {
            setDisplayAddmember(true);
          }}
          className="text-white text-center bg-green gap-3  w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
        >
          Add Members
        </button>
      </div>
      <GlimpsePopup
        popup={displayAddmember}
        onClose={() => {
          setDisplayAddmember(false);
        }}
        showCross={false}
        customClass="max-w-[347px]"
      >
        <GlimpseAddMember
          tablist={[{ name: "Manual Entry" }, { name: "Mass Upload" }]}
          onClose={() => {
            setDisplayAddmember(false);
          }}
          next={next}
          setNext={setNext}
        />
      </GlimpsePopup>
      {userPartnerDashboardData && (
        <div className="flex justify-between gap-5 p-2 overflow-auto ">
          <GlimpseSmallCard
            title={"Assigned Licenses"}
            className="min-w-[160px]"
          >
            <span className="text-[24px] text-green font-semibold">
              {userPartnerDashboardData.assigned_users}

              <div className="text-green text-[12px]">
                /{userPartnerDashboardData.total_memberships}
              </div>
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard
            title={"Available Licenses"}
            className="min-w-[160px]"
          >
            <span className="text-[24px] text-green font-semibold">
              {userPartnerDashboardData.available_members}

              <div className="text-green text-[12px]">
                /{userPartnerDashboardData.total_memberships}
              </div>
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard
            title={"Career Awareness"}
            className="min-w-[160px]"
          >
            <div className="flex flex-row items-start justify-center">
              {userPartnerDashboardData.badges_count.map(
                (item: BadgesCount, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-center text-sm text-center text-headerBlue"
                    >
                      <span className="px-4">
                        <img
                          src={`/assets/icons/${item.slug}_badge.png`}
                          alt="badge"
                          className="w-[45px] h-[45px]"
                        />
                      </span>
                      <span className="text-[8px]">{item.count}</span>
                    </div>
                  );
                }
              )}
            </div>
          </GlimpseSmallCard>
          <GlimpseSmallCard title={"Assigned Top 3"} className="min-w-[160px]">
            <div className="relative flex justify-between">
              <span className="text-[24px] text-green font-semibold">
                {userPartnerDashboardData.top_three_assigned_users}
              </span>
              <span className="absolute right-0 h-16 -top-[10px]">
                <DughnutComponent
                  centerPercentage={true}
                  centerValue={`${assignedTop3.toFixed(0)}%`}
                  data={chartDataTop3}
                  fontStyle="400 10px sans-serif"
                />
              </span>
            </div>
          </GlimpseSmallCard>
          <GlimpseSmallCard
            title={"Most Common Top 3"}
            className="min-w-[160px]"
            childrenClass="flex gap-8 justify-center h-full"
            titleClass="pb-1"
          >
            {userPartnerDashboardData.most_common_career_career?.map(
              (item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center text-center text-[10px] text-black w-[40px] h-full"
                  >
                    <span className="px-4">
                      <img
                        src={item.icon_url}
                        alt="top_glimpses"
                        className="min-w-[30px] h-[30px] sm:min-w-[37px] sm:h-[37px]"
                      />
                    </span>
                    <span className="">{item.name}</span>
                  </div>
                );
              }
            )}
          </GlimpseSmallCard>
        </div>
      )}

      <div className="flex flex-col gap-3 p-3 overflow-auto overflow-y-hidden w-100">
        <GlimpseTable
          columns={partnerTableColumn}
          data={partnerList ? partnerList.results : []}
          loadingData={isPartnerListLoading}
          totalData={partnerList?.count as number}
          currentPage={partnerPagination.page}
          totalPage={
            partnerList
              ? Math.ceil(partnerList.count / partnerPagination.page_size)
              : 0
          }
          enableFilter={true}
          enableExport={true}
          enableSearch={true}
          enablePagination={true}
          preventClickOn={["id"]}
          filterItems={filterItems}
          filterChanged={async (data, key) => {
            setFilterItems(data);
            let values = data.flatMap((group) =>
              group.filters.filter((filter) => filter.is_checked)
            );
            await setPartnerPagination((prevData) => ({
              ...prevData,
              status: "",
              top_three: "",
            }));
            values.map(async (item) => {
              if (
                item.name === StatusFilter.Active ||
                item.name === StatusFilter.Invited
              ) {
                await setPartnerPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  status:
                    item.name === StatusFilter.Active ||
                    item.name === StatusFilter.Invited
                      ? item.name
                      : "",
                }));
              }

              if (
                item.name ===PaginationFilter.ten ||
                item.name === PaginationFilter.default || item.name === PaginationFilter.fifty 
              ) {
                setPartnerPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  page_size: item.name === PaginationFilter.ten ||
                    item.name === PaginationFilter.default || item.name === PaginationFilter.fifty
                    ? item.name
                    : 25,
                }));
              }

              if (
                item.name === Top3Filter.Assigned ||
                item.name === Top3Filter.Unassigned
              ) {
                await setPartnerPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  top_three:
                    item.name === Top3Filter.Assigned ||
                    item.name === Top3Filter.Unassigned
                      ? item.name
                      : "",
                }));
              }
            });
          }}
          rowSelected={(data) => {
            data.status === "active" &&
              navigation(`/partner-dashboard/individual-insight/${data?.id}`);
          }}
          onSearch={async (data) => {
            await setPartnerPagination((prevData) => ({
              ...prevData,
              search: data.target.value,
              page: 1,
              page_size: 25,
            }));
          }}
          nextPage={() => {
            if (
              Math.ceil(
                partnerList
                  ? partnerList.count / partnerPagination.page_size
                  : 0
              ) <= partnerPagination.page
            ) {
              displayWarning("You are at end of the page");
            } else {
              setPartnerPagination((prevData) => ({
                ...prevData,
                page: prevData.page + 1,
              }));
            }
          }}
          previousPage={() => {
            if (partnerPagination.page <= 1) {
              displayWarning("You are already in page one.");
            } else {
              setPartnerPagination((prevData) => ({
                ...prevData,
                page: prevData.page - 1,
              }));
            }
          }}
          exportData={() => {
            downloadReport.mutateAsync({
              search: partnerPagination.search
                ? (partnerPagination.search as string)
                : null,
              status: partnerPagination.status
                ? partnerPagination.status
                : null,
              top_three: partnerPagination.top_three
                ? partnerPagination.top_three
                : null,
            });
          }}
        />

        {/* {(status === 'success' && partnerList.results.length > 0) ?  : <Loading text="Unable to fetch Partner Information" />} */}
      </div>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopupPasswordMember(false);
        }}
        showCross={true}
        popup={displayPopupPasswordMember}
        customClass="max-w-[347px]"
      >
        <div className="text-headerBlue text-[20px] flex items-start">
          Reset Member Password?
        </div>
        {popupMemberData && (
          <ResetPasswordMember
            memberInfo={popupMemberData as any}
            onClose={() => {
              setDisplayPopupPasswordMember(false);
            }}
          />
        )}
      </GlimpsePopup>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDeleteAlert(false);
        }}
        showCross={false}
        customClass="max-w-[347px]"
        popup={deleteAlert}
      >
        {popupMemberData && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Delete Member Account
            </div>
            <div>
              If you delete
              <span className="px-1 font-bold">
                {popupMemberData.member?.name}'s
              </span>
              membership, their account data will be permanently deleted. This
              action cannot be undone.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setDeleteAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-red w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleDeleteMemberSubmit(popupMemberData);
                  setDeleteAlert(false);
                }}
              >
                Sure Delete
              </button>
            </div>
          </>
        )}
      </GlimpsePopup>
    </>
  );
};

export default PartnerDashboard;
