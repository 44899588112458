export enum registrationEnum {
  subscription,
  user,
  payment,
}

export enum ProsCons {
  Pros = "pros",
  Cons = "cons",
}

export enum Status {
  Draft = "draft",
  Publish = "published",
  Archive = "archive",
}

export enum References {
  DidYouKnow = "Did You Know",
  Salary = "Salary",
  CareerDemand = "Career Demand",
  ImpactQuote = "Impact Quote",
}

export enum EducationRadio {
  Required = "required",
  Recommended = "recommended",
}

export enum toggleEnum {
  paid = "paid",
  comped = "comped",
}

export enum Top3Filter {
  Assigned = "assigned",
  Unassigned = "unassigned",
}

export enum StatusFilter {
  Active = "active",
  Invited = "invited",

}

export  enum PaginationFilter{
  ten=10 ,
  default=25,
  fifty=50
}

export enum CareerTypeEnum {
  preview = "preview",
  deepdive = "deep-dive",
  prioritize = "prioritize",
}

export enum VIdeoStatus {
  Completed = "completed",
  Unlocked = "unlocked",
}

export enum Badges {
  silver = "Silver Level",
  gold = "Gold Level",
  bronze = "Bronze Level",
}

export enum ImageType {
  career_photo = "career_photo",
  career_video = "career_video",
}

export enum CareerCategory {
  unsorted = "unsorted",
  top_three = "top_three",
  watch_list = "watch_list",
  discard = "discard",
}

export enum UnlockButton {
  continue = "continue",
  unlock = "unlock"
}
